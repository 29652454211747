import React, { Component, lazy, Suspense } from 'react';
import { Switch, Redirect } from 'react-router';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history'
import Loader from 'react-loader';

import 'main/js/assets/styles/index.css';
const Login = lazy(() => import ('main/js/pages/login/login'));
const Clients = lazy(() => import ('main/js/pages/clients/clients'));
const Licenses = lazy(() => import ('main/js/pages/licenses/licenses'));
const Projects = lazy(() => import ('main/js/pages/projects/projects'));

const history = createBrowserHistory()

export default class RouterConfig extends Component {
  render() {
    const {loggedIn} = this.props;

    const PrivateRoute = (props) =>
      <>
        { loggedIn ? props.children : <Redirect to='login' /> }
      </>

    return (
  	  <Router history={history}>
        <Suspense
          fallback={
            <Loader loading={true} />
          }
        >
          <Switch>
            <Route path='/login' component={Login} />
            <Route path='/' exact
              component={() =>
                (!loggedIn ? <Redirect to='/login' /> : <Redirect to='/licenses' />)
              }
            />

            <PrivateRoute>
              <Route path='/clients' component={Clients} />
              <Route path='/licenses' component={Licenses} />
              <Route path='/projects' component={Projects} />
            </PrivateRoute>
          </Switch>
        </Suspense>
      </Router>
    );
  }
}
