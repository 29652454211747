import axios from 'axios'

export default class AuthService {
  login(data) {
	  return axios.post('/authenticate', data).then(res => {
      this.setAuthInfo(res.data);
      this.setAuthHeaders();
      return Promise.resolve(res);
	  })
  }

  loggedIn() {
    const authInfo = this.getAuthInfo();
    return authInfo;
  }

  setAuthInfo(authInfo) {
    localStorage.setItem('auth_info', authInfo);
  }

  getAuthInfo() {
    return localStorage.getItem('auth_info');
  }

  removeAuthInfo() {
    return localStorage.removeItem('auth_info');
  }

  getAuthHeader() {
    return `Basic ${this.getAuthInfo()}`
  }

  setAuthHeaders() {
    const authInfo = this.getAuthInfo();
    if (authInfo) {
      axios.defaults.headers.common['Authorization'] = `Basic ${authInfo}`;
    }
  }

  removeAuthHeaders() {
    delete axios.defaults.headers.common['Authorization'];
  }

  logout = () => {
    return new Promise((resolve, reject) => {
      this.removeAuthInfo();
      this.removeAuthHeaders();
      resolve();
    });
  };
}
