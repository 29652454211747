import React from 'react';
import ReactDOM from 'react-dom';
import AuthService from 'main/js/util/authService';
import axios from 'axios';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';

import RouterConfig from './routerConfig';

const Auth = new AuthService();

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

registerLocale('ru', ru)
setDefaultLocale('ru')

if (Auth.loggedIn()) {
  Auth.setAuthHeaders();
}

ReactDOM.render(
  <RouterConfig
    loggedIn={Auth.loggedIn()}
  />,
  document.getElementById('root')
);
